import React from 'react';
import styled from '@emotion/styled';
import FlapUtil from 'Utils/content/flap-util';
import { SURFACE_COLORS, TEXT_COLORS } from 'Style/colors';
import { SPACING } from 'Style/spacing';
import { UI_HEADING_TYPES, BODY_TYPES, FONT_WEIGHTS } from 'Style/typography';
import { BREAKPOINTS } from 'Style/breakpoints';
import { PAGE_STYLES } from 'Style/layout';
import { styleObject } from 'Style/type-helpers';
import { truncate } from 'Style/truncate';

import { StyleVariations } from 'Webapp/shared/app/components/button';
import AuthorAvatar from 'Webapp/shared/app/components/attribution/author-avatar';
import MagazineInviteAcceptButton from 'Webapp/shared/app/components/magazine-invite-accept-button';

import connector from 'Utils/connector';
import connectUserState from 'Webapp/shared/app/connectors/connectUserState';
import connectResponsive, {
  ConnectResponsiveProps,
} from 'Webapp/shared/app/connectors/connectResponsive';
import connectCurrentUser, {
  ConnectCurrentUserProps,
} from 'Webapp/shared/app/connectors/connectCurrentUser';
import connectAuthentication, {
  ConnectAuthenticationProps,
} from 'Webapp/shared/app/connectors/connectAuthentication';
import connectAcceptMagazineInvite, {
  ConnectAcceptMagazineInviteProps,
} from 'Webapp/shared/app/connectors/connectAcceptMagazineInvite';
import withT from 'ComponentLibrary/hocs/withT';

const AVATAR_SIZE = 96;

interface MagazineInviteBannerState {
  closeBanner: boolean;
  authorDisplayName: string | null;
}

interface MagazineInviteBannerPassedProps {
  section: Flipboard.Section;
  t: Flipboard.TFunction;
  magazineInvite: Flipboard.State['magazineInvite'];
}

type MagazineInviteBannerProps = MagazineInviteBannerPassedProps &
  ConnectCurrentUserProps &
  ConnectResponsiveProps &
  ConnectAuthenticationProps &
  ConnectAcceptMagazineInviteProps;

const MagazineBanner = styled('div')(
  PAGE_STYLES.CENTERED_COLUMN,
  {
    background: SURFACE_COLORS.secondary,
    color: TEXT_COLORS.primary,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    height: '128px',
  },
  styleObject(
    BREAKPOINTS.phone({
      flexDirection: 'column',
      height: '100%',
      textAlign: 'center',
      padding: `${SPACING.LARGE}`,
    }),
  ),
);

const InviterAvatar = styled(AuthorAvatar)(
  styleObject(BREAKPOINTS.tabletPortraitUp({ minWidth: `${AVATAR_SIZE}px` })),
  styleObject(BREAKPOINTS.phone({ height: `${AVATAR_SIZE}px` })),
);

const AvatarAndTextContainer = styled.div(
  {
    display: 'flex',
    alignItems: 'center',
  },
  styleObject(
    BREAKPOINTS.phone({
      flexDirection: 'column',
      height: '100%',
    }),
  ),
);

const TextDescription = styled.div(
  {
    padding: SPACING.XLARGE,
  },
  styleObject(
    BREAKPOINTS.phone({
      padding: SPACING.LARGE,
    }),
  ),
);

const HeaderText = styled.h1(truncate('100%', 2), UI_HEADING_TYPES.MEDIUM);

const SubHeaderText = styled.div({
  paddingTop: SPACING.MEDIUM,
});

const StyledAcceptInviteButton = styled(MagazineInviteAcceptButton)(
  BODY_TYPES.LARGE_STANDARD,
  {
    height: '40px',
    width: '130px',
    '.loading-spinner': {
      width: '100%',
    },
    color: TEXT_COLORS.overlay,
    fontWeight: FONT_WEIGHTS.SEMIBOLD,
    lineHeight: '16px',
  },
);

class MagazineInviteBanner extends React.Component<
  MagazineInviteBannerProps,
  MagazineInviteBannerState
> {
  state = {
    closeBanner: false,
    submitting: false,
    authorDisplayName: '',
  };

  componentDidMount() {
    // Cache the authorDisplayName from section.invite.
    // After accepting magazine invite, section.invite is not longer available
    const { section } = this.props;
    if (section?.invite) {
      this.setState({
        authorDisplayName: section.invite.authorDisplayName,
      });
    }
  }

  componentDidUpdate(
    _prevProps: MagazineInviteBannerProps,
    prevState: MagazineInviteBannerState,
  ) {
    if (!prevState.closeBanner && this.state.closeBanner) {
      this.props.showMainNavCTAs();
    }
  }

  componentWillUnmount() {
    this.props.showMainNavCTAs();
  }

  render() {
    const {
      section,
      magazineInvite,
      isMagazineInvitePending,
      t,
      profile,
      isAuthenticated,
    } = this.props;

    const { closeBanner } = this.state;

    if (closeBanner) {
      return null;
    }

    const isAlreadyContributor = (
      profile?.contributorMagazines as Array<Flipboard.Section>
    ).find((s) => s.remoteid === section?.remoteid);

    if (
      !magazineInvite?.sectionId ||
      (isAlreadyContributor && !isMagazineInvitePending) ||
      !section ||
      !FlapUtil.isRemoteIdSectionMatch(magazineInvite.sectionId, section)
    ) {
      return null;
    }

    return (
      <MagazineBanner>
        <AvatarAndTextContainer>
          <InviterAvatar author={section} avatarSize={AVATAR_SIZE} />
          <TextDescription>
            <HeaderText>
              {t('youre_invited', {
                sectionTitle: section.title,
                authorDisplayName: this.state.authorDisplayName,
              })}
            </HeaderText>
            <SubHeaderText>
              {isAuthenticated
                ? t('invite_sub_text_logged_in', {
                    sectionTitle: section.title,
                    authorDisplayName: this.state.authorDisplayName,
                  })
                : t('invite_sub_text', { sectionTitle: section.title })}
            </SubHeaderText>
          </TextDescription>
        </AvatarAndTextContainer>
        <StyledAcceptInviteButton
          styleVariation={StyleVariations.PRIMARY}
          section={section}
        />
      </MagazineBanner>
    );
  }
}

export default connector(
  connectUserState,
  connectResponsive,
  connectCurrentUser,
  connectAuthentication,
  connectAcceptMagazineInvite,
)(withT(MagazineInviteBanner));
